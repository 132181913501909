// 设置用户vos相关信息
<template>
    <div class="setVosList">
        <div class="flex-nowrap-space-between mb-10">
            <div>
                账户：{{phone}}
            </div>
            <div class="ml-10">
                <el-button class="button-el" size="mini" @click="addData">
                    <icon-font type="icon-tianjia1" class="button-icon-font"/>
                    添加VOS设置
                </el-button>
            </div>
        </div>
        <div>
            <el-table 
                :data="tableData" 
                style="width: 100%"
                size="mini"
                v-loading="loading"
                tooltip-effect="dark"
                :row-style="{height:'50px'}"
                :cell-style="{padding:'0px'}"
                :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            >
                <!-- <el-table-column 
                    prop="id" 
                    label="主键ID"
                >
                    <template #default="scope">
                        <span>{{ scope.row.id == null ? "-" : scope.row.id }}</span>
                    </template>
                </el-table-column> -->
                <el-table-column 
                    prop="price" 
                    label="价格"
                >
                    <template #default="scope">
                        <span>{{ scope.row.price == null ? "-" : scope.row.price }}元</span>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="telAPrefix" 
                    label="TelA前缀"
                >
                    <template #default="scope">
                        <span>{{ scope.row.telAPrefix == null ? "-" : scope.row.telAPrefix }}</span>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="telBPrefix" 
                    label="TelB前缀"
                >
                    <template #default="scope">
                        <span>{{ scope.row.telBPrefix == null ? "-" : scope.row.telBPrefix }}</span>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="chance" 
                    label="抽检比例"
                >
                    <template #default="scope">
                        <span>{{ scope.row.chance == null ? "-" : scope.row.chance }}%</span>
                    </template>
                </el-table-column>
                <el-table-column  
                    label="操作"
                >
                    <template #default="scope">
                        <div class="flex-nowrap-space-around">
                            <span class="button-font-color" @click="editorData(scope.row)">
                                <icon-font type="icon-bianji"/>
                                编辑
                            </span>
                            <span class="button-font-color" @click="deleteData(scope.row)">
                                <icon-font type="icon-copy-4-03"/>
                                删除
                            </span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- <div class="flex-nowrap-flex-end">
            <div class="mt-10 mb-10">
                <el-pagination 
                    background 
                    v-model:currentPage="currentPage"
                    layout="prev, pager, next" 
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :total="1000">
                </el-pagination>
            </div>
        </div> -->
        <div>
            <!-- 添加/修改信息 -->
            <el-dialog
                v-model="editorInnerVisible"
                width="380px"
                :title="editorType == 0?'添加':'编辑'"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                append-to-body
            >
                <div>
                    <el-form
                        label-position="left"
                        label-width="80px"
                        size="small"
                        :model="upform"
                        ref="upForm"
                        :rules="rules"
                    >
                        <el-form-item label="价格" prop="price">
                            <el-input v-model="upform.price" placeholder="请输入价格(可保留3位小数)" size="mini">
                                <template #append>元/小时</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="TelA前缀" prop="telAPrefix">
                            <el-input v-model="upform.telAPrefix" placeholder="请输入TelA前缀(6位以内整数)" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="TelB前缀" prop="telBPrefix">
                            <el-input v-model="upform.telBPrefix" placeholder="请输入TelB前缀(6位以内整数)" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item label="抽检比例" prop="chance">
                            <el-input v-model="upform.chance" placeholder="请输入抽检比例(0~100)" size="mini">
                                <template #append>%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <div class="flex-nowrap-flex-end">
                                <el-button size="small" @click="resetForm('upForm')">取消</el-button>
                                <el-button size="small" class="button-confirm" @click="submitForm('upForm')">确定</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { IconFont } from "@/utils/iconfont"
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'
import vos from '@/api/open/openVoiceVosUserInstall/openVoiceVosUserInstall'
export default {
    props: {
        uid: {
            type: String,
            default: ''
        },
        phone: { 
            type: String,
            default: ''
        }
    },
    components: {
        IconFont,
    },
    data () {
        const validatePrice = (rule,value,callback) =>{
            let reg = /^-?\d+(\.\d{1,3})?$/
            if(!value){
                callback(new Error('价格不能为空'))
            }else if(!reg.test(value)){
                callback(new Error('请输入正确的格式（保留3位小数）'))
            }else if(value.length > 10){
                callback(new Error('最多可输入10个字符'))
            }else{
            callback();
            }
        };
        const validateTelA = (rule,value,callback) =>{
            let reg = /^(([1-9]{1}\d*)|(0{1}))$/
            if(!value){
                callback(new Error('前缀不能为空'))
            }else if(!reg.test(value)){
                callback(new Error('请输入正确的格式（6位以内整数）'))
            }else if(value.length > 6){
                callback(new Error('最多可输入6个字符'))
            }else{
            callback();
            }
        };
        const validateChance = (rule,value,callback) =>{
            let reg = /^(?:[1-9]?\d|100)$/
            if(!value){
                callback(new Error('抽检比例不能为空'))
            }else if(!reg.test(value)){
                callback(new Error('请输入正确的格式（1~100的整数）'))
            }else if(value.length > 6){
                callback(new Error('最多可输入3个字符'))
            }else{
            callback();
            }
        };
        return {
            tableData:[],
            editorInnerVisible:false,
            addInnerVisible:false,
            currentPage:1,
            editorType:0,
            upform:{
                uid:'',
                id:'',
            },
            pageForm:{
                
            },
            loading:false,
            rules:{
                price: [
                    {required: true,  trigger: 'blur', validator:validatePrice}
                ],
                telAPrefix: [
                    {required: true,  trigger: 'blur', validator:validateTelA}
                ],
                telBPrefix: [
                    {required: true,  trigger: 'blur', validator:validateTelA}
                ],
                chance: [
                    {required: true,  trigger: 'blur', validator:validateChance}
                ],
            }
        }
    },
    watch: {

    },
    mounted() {
        this.upform.uid = this.uid
        // console.log(this.phone)
        this.getTableData(true)
    },
    methods: {
        getTableData(type){
            this.loading = type
            vos.getByUidVos({
                uid:this.upform.uid
            }).then(res=>{
                this.loading = false
                this.tableData = res.data
            })
        },
        // 修改信息
        editorData(row){
            this.editorType = 1
            this.upform.id = row.id
            vos.getVos({
                id:row.id
            }).then(res=>{
                this.upform = res.data
                this.editorInnerVisible = true
            })
            
        },
        addData(){
            this.editorType = 0
            this.upform.id = ''
            this.editorInnerVisible = true
        },
        // 删除信息
        deleteData(row){
            ElMessageBox.confirm(
                    '是否确定删除该项?',
                    '注意',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false
                }
            )
            .then(() => {
                vos.deleteVos({
                    id:row.id
                }).then(res=>{
                    ElMessage({
                        type: 'success',
                        message: '删除成功！',
                    })
                    this.getTableData(true)
                })
                
            })
            .catch(() => {
                ElMessage({
                    type: 'info',
                    message: '已取消删除！',
                })
            })
        },
        // 确认
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.editorType == 0){//添加
                        vos.addVos({
                            uid:this.upform.uid,
                            price:this.upform.price,
                            telAPrefix:this.upform.telAPrefix,
                            telBPrefix:this.upform.telBPrefix,
                            chance:this.upform.chance
                        }).then(res=>{
                            ElMessage({
                                type: 'success',
                                message: '添加成功！',
                            })
                            this.editorInnerVisible = false
                            this.getTableData(true)
                        })
                    }else{//修改
                        vos.editVos({
                            uid:this.upform.uid,
                            id:this.upform.id,
                            price:this.upform.price,
                            telAPrefix:this.upform.telAPrefix,
                            telBPrefix:this.upform.telBPrefix,
                            chance:this.upform.chance
                        }).then(res=>{
                            ElMessage({
                                type: 'success',
                                message: '修改成功！',
                            })
                            this.editorInnerVisible = false
                            this.getTableData(true)
                        })
                    }
                    
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 取消
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.editorInnerVisible = false
        },
    }
}
</script>

<style lang="scss" scoped>

</style>