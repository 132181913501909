import { Request } from '@/http/request'

// 添加
export function addVos(parameter: any){
  return Request.axiosInstance.post('/open/voice-vos-user-config/add', parameter)
}
// 删除多个 使用, 英文逗号隔开
export function deleteVos(parameter: any){
  return Request.axiosInstance.post('/open/voice-vos-user-config/delete', parameter)
}
// 修改
export function editVos(parameter: any){
  return Request.axiosInstance.post('/open/voice-vos-user-config/edit', parameter)
}
// 分页查询
export function getVos(parameter: any){
  return Request.axiosInstance.post('/open/voice-vos-user-config/get', parameter)
}
// 按照用户id查询设置集合
export function getByUidVos(parameter: any){
  return Request.axiosInstance.post('/open/voice-vos-user-config/get-by-uid', parameter)
}
// 分页查询
export function listVos(parameter: any){
  return Request.axiosInstance.post('​/open/voice-vos-user-config/list', parameter)
}
// 用户批量修改
export function saveOrUpdateVos(parameter: any){
  return Request.axiosInstance.post('​/open/voice-vos-user-config/save-or-update', parameter)
}

export default { addVos, deleteVos, editVos, getVos, getByUidVos, listVos }